import { useI18n } from 'vue-i18n';
import { computed, type ComputedRef } from 'vue';
import { format } from 'date-fns';

interface DateFormatter {
    dateFormatter: {
        formatForZod(date: Date): string;
        formatDate(date: Date, dateStyle?: 'long' | 'short'): string;
        formatDateTwoDigits(date: Date): string;
    };
    relativeDateFormatter: {
        formatWeeks(weeks: number): ComputedRef<string>;
    };
}

export function useDateFormatter(): DateFormatter {
    const i18n = useI18n();

    return {
        dateFormatter: {
            formatForZod(date: Date): string {
                return format(date, 'yyyy-MM-dd');
            },
            formatDate(date: Date, dateStyle: 'long' | 'short' = 'long'): string {
                const locale = i18n.locale.value;
                const formatter = new Intl.DateTimeFormat(locale, {
                    dateStyle,
                });

                return formatter.format(date);
            },
            formatDateTwoDigits(date: Date): string {
                const locale = i18n.locale.value;
                const formatter = new Intl.DateTimeFormat(locale, {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                });

                return formatter.format(date);
            },
        },
        relativeDateFormatter: {
            formatWeeks(weeks: number): ComputedRef<string> {
                return computed(() => {
                    const locale = i18n.locale.value;
                    const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

                    return formatter.format(weeks, 'weeks');
                });
            },
        },
    };
}
